<script>
import {BASE} from "@/mixins";
import BaseViewMap from "@/components/partials/modals/BaseViewMap.vue";
import {checkYachtDetails, toggleFavorite} from "@/utilities/service";
import {mapActions, mapGetters} from "vuex";
import {addToFavorites, removeFromFavorites} from "@/api";

export default {
  name: "BoatCard",
  components: { BaseViewMap},
  mixins: [BASE],
  props: {
    yacht: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showBaseLocation: false,
      selectedYacht: {},
      isFavoriteRequested: false,
      localFavorites: JSON.parse(localStorage.getItem('favorites')) || [],
    }
  },
  methods: {
    toggleFavorite,
    checkYachtDetails,
    removeFromFavorites,
    addToFavorites,
    ...mapActions(["getUserInfo", "fetchFavouriteYachts"]),
    openBaseMapModal(yacht) {
      this.selectedYacht = yacht;
      document.body.style.overflow = "hidden";
      this.showBaseLocation = true;
    },
    closeBaseMapModal() {
      document.body.style.overflow = "";
      this.selectedYacht= {};
      this.showBaseLocation = false;
    },

    async performFavorites(yacht) {
      if(this.isFavoriteRequested) {
        return;
      }
      this.isFavoriteRequested = true;

      if (!this.getUser.authentificated) {
        this.toggleFavorite(yacht);
        this.localFavorites = JSON.parse(localStorage.getItem('favorites')) || [];
      } else {
        if (this.getUser.favorites.includes(yacht.slug)) {
          const yachtIndex = this.getUser.favorites.findIndex(slug => slug === yacht.slug);
          this.getUser.favorites.splice(yachtIndex, 1);

          this.removeFromFavorites(yacht.slug, this.getToken).then(() => {
            this.getUser.favorites = this.getUser.favorites.filter(fav => fav.slug !== yacht.slug);
          });
        } else {
          this.getUser.favorites.push(yacht.slug);
          await this.addToFavorites(yacht.slug, this.getToken);
        }
      }
      const authToken = localStorage.getItem('authToken');
      if (authToken) {
        this.user = await this.getUserInfo({accessToken: authToken, innerUser: true});
      }
      this.$emit('trigger-update-in-header');
      this.isFavoriteRequested = false;
      await this.fetchFavouriteYachts();
    }
  },
  computed:{
    ...mapGetters(["getUser", "getToken"]),
    yachtName(){
      return this.selectedYacht.name ? this.selectedYacht.model + ' | ' + this.selectedYacht.name : this.selectedYacht.model;
    },
    isFavorite(){
      if (!this.getUser || !this.getUser.authentificated) {
        if (!this.localFavorites) return false;
        return this.localFavorites && this.localFavorites.some(slug => slug === this.yacht.slug);
      } else if (this.getUser) {
        return this.getUser.favorites && this.getUser.favorites.some(slug => slug === this.yacht.slug);
      } else {
        return false;
      }
    }
  },
  mounted() {
  }
}
</script>

<template>
  <a class="product" @click="checkYachtDetails">
    <div class="product__left">
      <div class="product__photo">
        <img v-if="yacht.images" :src="yacht.images[400] || yacht.images[800]" :alt="yacht.model + ' | ' + yacht.name">
        <img v-else src="/images/default-small.png" :alt="yacht.model + ' | ' + yacht.name"></div>
<!--      <div class="product__advantages">-->
<!--        <div class="product__advantage"><img src="/images/advantage-1.svg" alt="Bathing platform"><span>Bathing platform</span></div>-->
<!--        <div class="product__advantage"><img src="/images/advantage-2.svg" alt="Outside GPS plotter"><span>Outside GPS plotter</span></div>-->
<!--        <div class="product__advantage"><img src="/images/advantage-3.svg" alt="Outside speakers"><span>Outside speakers</span></div>-->
<!--      </div>-->
    </div>
    <div class="product__right">
      <div class="product__title">
        <div class="product__flag">
          <span v-if="yacht.icon" :class="'fi icon-flag fi-'+yacht.icon.toLowerCase()"></span>
        </div>
        <div class="prodcut__name">{{ yacht.model }} <template v-if="yacht.name">|</template> {{ yacht.name }}</div>
        <div class="add-to-favorite" @click.stop="performFavorites(yacht)">
          <svg width="30" height="30" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
            <path
                :d="isFavorite ? 'M24.9707 6.70088C22.7028 4.43304 19.0274 4.43304 16.6813 6.70088L15.0391 8.34305L13.3969 6.70088C11.0512 4.43304 7.37571 4.43304 5.10787 6.70088C2.84 8.96874 2.84 12.6442 5.10787 14.9121L15.0391 25L24.9707 14.9121C27.2385 12.6442 27.2385 8.96874 24.9707 6.70088Z' : 'M9.56499 13.8954L16.6813 6.70088C19.0274 4.43304 22.7028 4.43304 24.9707 6.70088C27.2385 9.04692 27.2385 12.7224 24.9707 14.9902L15.0391 25L5.02931 14.9902C2.76147 12.7224 2.76147 9.04692 5.02931 6.70088C6.90614 4.82405 9.79959 4.51124 11.9892 5.76246'"
                :fill="isFavorite ? '#BF8D37' : 'none'"
                stroke="#BF8D37"
                stroke-width="3"
                stroke-miterlimit="22.9256"
            ></path>
          </svg>
        </div>
      </div>
      <div
          class="product__place"
          @click.stop="openBaseMapModal(yacht)"
          title="Click to see location"
      >{{ yacht.city }}, {{ yacht.base }} </div>
      <div class="product__info">
        <ul>
          <li v-if="yacht.year"><span>{{ $t('yacht.year') }}</span><span>{{ yacht.year }}</span></li>
          <li v-if="yacht.max_people"><span>{{ $t('yacht.people') }}</span><span>{{ yacht.max_people }}</span></li>
          <li v-if="yacht.cabins"><span>{{ $t('yacht.cabins') }}</span><span>{{ yacht.cabins }}</span></li>
        </ul>
        <ul>
          <li v-if="yacht.type"><span>{{ $t('yacht.yacht_type') }}</span><span>{{ yacht.type }}</span></li>
          <li v-if="yacht.length"><span>{{ $t('yacht.length') }}</span><span>{{ yacht.length }} m</span></li>
          <li v-if="yacht.main_sail_type"><span>{{ $t('yacht.mainsail') }}</span><span>{{ yacht.main_sail_type }}</span></li>
        </ul>
      </div>
      <div class="product__price">

        <div v-if="yacht.price" class="product__price-val"> <span>{{ yacht.price }} € </span>/ {{ $t('interface.week') }}</div>
        <div v-else-if="yacht.min_price" class="product__price-val">{{ $t('interface.from') }} <span>{{ yacht.min_price }} € </span>/ {{ $t('interface.week') }}</div>
        <div v-else class="product__price-val product__price-val__upper">{{ $t('interface.by_request') }}</div>
        <div class="btn"><span>{{ $t('actions.view_yacht') }}</span></div>
      </div>
    </div>
  </a>
  <div class="overLay" v-if="showBaseLocation" @click="closeBaseMapModal"></div>
  <BaseViewMap
      v-if="showBaseLocation"
      :base="{latitude:selectedYacht.latitude,longitude:selectedYacht.longitude}"
      :yacht-name="yachtName"
      @closeBaseMapModal="closeBaseMapModal" />
</template>


<style scoped>
.product__place,
.prodcut__name,
.product__photo {
  cursor: pointer;
}
.product__price-val__upper {
  text-transform: uppercase;
}

.overLay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1001;
}
.product__flag {
  align-content: flex-start;
  align-items: baseline;
}
.icon-flag {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  display: block;
  width: 24px;
  min-width: 24px;
  height: 18px;
}
</style>
