import axios from 'axios';
import loginForm from "@/components/partials/modals/LoginForm.vue";

const URI_API = process.env.VUE_APP_API_URL;

const state = {
    selectedYacht: {}
};

const mutations = {
    SET_SELECTED_YACHT(state, selectedYacht) {
        state.selectedYacht = selectedYacht;
    },
    UPDATE_PERIOD_PRICE(state, periodPrice) {
        state.selectedYacht.period_price = periodPrice;
    }
};

const actions = {
    async fetchSelectedYacht({ commit }) {
        try {
            const currentPath = window.location.pathname;
            const lastSlug = currentPath.substring(currentPath.lastIndexOf("/") + 1);
            const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;

            const yachtUrl = `${URI_API}/yacht/${lastSlug}`;

            const response = await axios.get(yachtUrl, {
                headers: {
                    "Accept": "application/json",
                    'Authorization': `Bearer ${tokenBearer}`
                },
            });

            if (!response.statusText) {
                throw new Error("Failed to fetch yacht details");
            }

            const selectedYacht = response.data.data;

            const mainImageUrls = selectedYacht.mainImageUrls;
            if (mainImageUrls) {
                if (!mainImageUrls['800']) {
                    mainImageUrls['800'] = mainImageUrls['400'] || mainImageUrls['original'] || "/images/default-big.png";
                }
            } else {
                selectedYacht.mainImageUrls = { "800": "/images/default-big.png" };
            }

            commit('SET_SELECTED_YACHT', selectedYacht);

            // Fetch and update period prices
            const periodPrices = await updatePeriodPrices(selectedYacht.id);
            commit('UPDATE_PERIOD_PRICE', periodPrices);

        } catch (error) {
            console.error("Error fetching yacht details:", error);
        }
    }
};

const getters = {
    getSelectedYacht: state => state.selectedYacht,
};

export default {
    state,
    mutations,
    actions,
    getters
};

// Function to update period prices
async function updatePeriodPrices(yachtId) {
    const url = `${URI_API}/yacht-period-prices/${yachtId}`;
    const tokenBearer = process.env.VUE_APP_API_KEY_BEARER;

    try {
        const response = await axios.get(url, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${tokenBearer}`
            }
        });
        return response.data.data;
    } catch (error) {
        console.error("Error fetching period prices:", error);
        return [];
    }
}
